import React from 'react';
import cc from 'classcat';

interface Props {
  children: React.ReactNode
  className?: string
  icon: React.ReactElement
  title: React.ReactNode
}

const Feature: React.FC<Props> = (props) => {
  const {
    children,
    className,
    icon,
    title,
  } = props;

  return (
    <li>
      <div
        className={cc([
          'fc-feature',
          'flex',
          className,
        ])}
      >
        <div className='flex-shrink-0'>
          <div className='flex items-center justify-center h-12 w-12 rounded-lg bg-primary text-gray-900'>
            {icon}
          </div>
        </div>
        <div className='ml-4'>
          <h3 className='text-2xl leading-8 font-bold text-gray-900 dark:text-white font-display'>
            {title}
          </h3>
          <div className='mt-2 text-lg leading-8 text-gray-700 dark:text-gray-500'>
            {children}
          </div>
        </div>
      </div>
    </li>
  );
};

Feature.displayName = 'FcFeature';

export default Feature;
