import React from 'react';

interface Props {
  children: React.ReactNode
}

const Features: React.FC<Props> = (props) => {
  const {
    children,
  } = props;

  return (
    <div className="fc-features py-12">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        {
          false &&
          <div className="lg:text-center">
            {/* <p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Transactions
            </p> */}
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              A better way to send money
            </h3>
            <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
              Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.
            </p>
          </div>
        }

        <div>
          <ul className="md:grid md:grid-cols-2 md:gap-8 md:row-gap-10">
            {children}
          </ul>
        </div>
      </div>
    </div>
  );
};

Features.displayName = 'FcFeatures';

export default Features;
