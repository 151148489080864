import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import { renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import Layout from '../components/layout';
import SEO from '../components/seo';

import ArrowButton from '../components/arrow-button';
import Card from '../components/card';
import Icon, { IconName } from '../components/icon';
import Section from '../components/section';
import Jumbotron from '../components/jumbotron';
import Features, { Feature } from '../components/features';
import SocialMediaButtons from '../components/social-media-button/social-media-buttons';

interface Service {
  id: string;
  name: string;
  serviceId: string;
  shortDescription: RenderRichTextData<any>;
  featuredImage: {
    description: string;
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
}

interface Principle {
  id: string;
  title: string;
  icon: {
    name: IconName;
  };
  description: RenderRichTextData<any>;
}

type DataProps = {
  companyInfo: {
    entity: {
      companyName: string;
    };
  };
  scoutq: Service;
  screenq: Service;
  walq: Service;
  principles: {
    edges: {
      principle: Principle;
    }[];
  };
};

const IndexPage: React.FC<PageProps<DataProps>> = (props) => {
  const { data, location } = props;

  const intl = useIntl();

  return (
    <>
      <SEO
        title={intl.formatMessage({
          id: 'Services around places you care about',
          defaultMessage: 'Services around places you care about',
        })}
      />

      <Layout
        className="px-4 md:px-0"
        path={location.pathname}
        messages={[]}
        title={
          <span>
            <FormattedMessage
              id="Services around places"
              defaultMessage="Services around places"
            />{' '}
            <span className="text-primary">
              <FormattedMessage id="you care about" defaultMessage="you care about" />
            </span>
          </span>
        }
        image={
          <StaticImage
            alt={intl.formatMessage({
              id: 'An aerial image of the river in Gothenburg',
              defaultMessage: 'An aerial image of the river in Gothenburg',
            })}
            src="../assets/images/edvin-johansson-lrhtitoYwGs-unsplash.jpg"
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            draggable={false}
            placeholder="blurred"
          />
        }
        preMain={
          <Jumbotron
            className="bg-black"
            /* buttons={[
              <Button
                href='#'
                variant='primary'
              >
                Learn more
              </Button>,
              <Button
                href='#'
                variant='secondary'
              >
                Learn more
              </Button>
            ]} */
            title={
              <span className="text-gray-200">
                <FormattedMessage
                  id="Are you ready to dive in?"
                  defaultMessage="Are you ready to dive in?"
                />
                <br />
                <span className="text-primary">
                  <FormattedMessage
                    id="Great! Just hold tight."
                    defaultMessage="Great! Just hold tight."
                  />
                </span>
              </span>
            }
            subTitle={
              <span className="text-gray-300">
                <FormattedMessage
                  id={
                    "We're not ready to release our services to the world just yet, but we hope to see you when we are."
                  }
                  defaultMessage={
                    "We're not ready to release our services to the world just yet, but we hope to see you when we are."
                  }
                />
              </span>
            }
          />
        }
      >
        <Section
          id="services"
          title={intl.formatMessage({
            id: 'Services',
            defaultMessage: 'Services',
          })}
          subTitle={intl.formatMessage(
            {
              id: '{entity} creates services centered around places, whether that be bus stops, events or scavenger hunts. We package these in a way that is easy to use and understand. Simplified for you.',
              defaultMessage:
                '{entity} creates services centered around places, whether that be bus stops, events or scavenger hunts. We package these in a way that is easy to use and understand. Simplified for you.',
            },
            {
              entity: data.companyInfo.entity.companyName,
            }
          )}
        >
          <div className="mb-4 flex lg:flex-row flex-wrap lg:flex-no-wrap">
            <div className="lg:w-1/3 px-2 order-2 lg:order-1">
              <Card
                className="mb-8 lg:mb-4"
                image={
                  <GatsbyImage
                    alt={data.walq.featuredImage.description}
                    image={data.walq.featuredImage.localFile.childImageSharp.gatsbyImageData}
                    draggable={false}
                    className="w-full"
                  />
                }
                title={data.walq.name}
                footer={
                  <div className="text-right">
                    <ArrowButton
                      href={`/services/${data.walq.serviceId}`}
                      small={true}
                      type="internal"
                      variant="tertiary"
                    >
                      <FormattedMessage
                        id="Learn more about {topic}"
                        defaultMessage="Learn more about {topic}"
                        values={{
                          topic: data.walq.name,
                        }}
                      />
                    </ArrowButton>
                  </div>
                }
              >
                {renderRichText(data.walq.shortDescription)}
              </Card>

              <Card
                className="mb-8 md:mb-4"
                image={
                  <GatsbyImage
                    alt={data.scoutq.featuredImage.description}
                    image={data.scoutq.featuredImage.localFile.childImageSharp.gatsbyImageData}
                    draggable={false}
                    className="w-full"
                  />
                }
                title={data.scoutq.name}
                footer={
                  <div className="text-right">
                    <ArrowButton
                      href={`/services/${data.scoutq.serviceId}`}
                      small={true}
                      type="internal"
                      variant="tertiary"
                    >
                      <FormattedMessage
                        id="Learn more about {topic}"
                        defaultMessage="Learn more about {topic}"
                        values={{
                          topic: data.scoutq.name,
                        }}
                      />
                    </ArrowButton>
                  </div>
                }
              >
                {renderRichText(data.scoutq.shortDescription)}
              </Card>
            </div>

            <div className="lg:w-2/3 px-2 order-1 lg:order-2">
              <Card
                className="mb-8 lg:mb-4"
                image={
                  <GatsbyImage
                    alt={data.screenq.featuredImage.description}
                    image={data.screenq.featuredImage.localFile.childImageSharp.gatsbyImageData}
                    draggable={false}
                    className="w-full"
                  />
                }
                title={data.screenq.name}
                footer={
                  <div className="text-right">
                    <ArrowButton
                      href={`/services/${data.screenq.serviceId}`}
                      small={true}
                      type="internal"
                      variant="tertiary"
                    >
                      <FormattedMessage
                        id="Learn more about {topic}"
                        defaultMessage="Learn more about {topic}"
                        values={{
                          topic: data.screenq.name,
                        }}
                      />
                    </ArrowButton>
                  </div>
                }
              >
                {renderRichText(data.screenq.shortDescription)}
              </Card>
            </div>
          </div>
        </Section>

        <Section
          id="principles"
          title={intl.formatMessage({
            id: 'Principles',
            defaultMessage: 'Principles',
          })}
        >
          <Features>
            {data.principles.edges.map(({ principle }) => (
              <Feature
                key={principle.id}
                icon={<Icon name={principle.icon.name} className="h-1/2" />}
                title={principle.title}
              >
                {renderRichText(principle.description)}
              </Feature>
            ))}
          </Features>
        </Section>

        {/* <Section
          id='company'
          title={intl.formatMessage({
            id: 'Company',
            defaultMessage: 'Company',
          })}
          // subTitle={intl.formatMessage({
          //   id: '{entity} is built on our purpose of <strong>bringing services to places you care about</strong>, and our core values; <red>dare</red>, <purple>care</purple> and <blue>simplify</blue>. It is why we exist and our guiding compass in everything we do, which is reflected in how we act and behave in our daily work.',
          //   defaultMessage: '{entity} is built on our purpose of <strong>bringing services to places you care about</strong>, and our core values; <red>dare</red>, <purple>care</purple> and <blue>simplify</blue>. It is why we exist and our guiding compass in everything we do, which is reflected in how we act and behave in our daily work.',
          // }, {
          //     entity: data.companyInfo.entity.companyName,
          //     strong: (chunks: any) => (
          //       <strong className='inline font-display'>{chunks}</strong>
          //     ),
          //     red: (chunks: any) => (
          //       <strong className="text-red-400 font-display">{chunks}</strong>
          //     ),
          //     purple: (chunks: any) => (
          //       <strong className="text-purple-600 font-display">{chunks}</strong>
          //     ),
          //     blue: (chunks: any) => (
          //       <strong className="text-blue-500 font-display">{chunks}</strong>
          //     ),
          //   }
          // )}
        >
          <div className="grid grid-cols-1 md:grid-cols-2">

          </div>
        </Section> */}

        <Section
          id="contact"
          title={intl.formatMessage({
            id: 'Contact',
            defaultMessage: 'Contact',
          })}
        >
          <SocialMediaButtons />
        </Section>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($locale: String) {
    companyInfo {
      entity {
        companyName
      }
    }
    principles: allContentfulPrinciple(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: sort }
    ) {
      edges {
        principle: node {
          id
          title
          icon {
            name
          }
          description {
            raw
          }
        }
      }
    }
    scoutq: contentfulService(serviceId: { eq: "scoutq" }, node_locale: { eq: $locale }) {
      id
      name
      serviceId
      shortDescription {
        raw
      }
      featuredImage {
        description
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, height: 300, placeholder: BLURRED)
          }
        }
      }
    }
    screenq: contentfulService(serviceId: { eq: "screenq" }, node_locale: { eq: $locale }) {
      id
      name
      serviceId
      shortDescription {
        raw
      }
      featuredImage {
        description
        localFile {
          childImageSharp {
            gatsbyImageData(width: 700, height: 500, placeholder: BLURRED)
          }
        }
      }
    }
    walq: contentfulService(serviceId: { eq: "walq" }, node_locale: { eq: $locale }) {
      id
      name
      serviceId
      shortDescription {
        raw
      }
      featuredImage {
        description
        localFile {
          childImageSharp {
            gatsbyImageData(width: 475, height: 500, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
